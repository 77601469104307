<template>
    <v-card flat>
        <v-card-title>
            <div class="d-flex align-center justify-space-between secondary--text w-100">
                <div class="d-flex flex-column">
                    <span class="font-weight-bold">User Settings</span>
                </div>
            </div>
        </v-card-title>
        <v-card-text>
            <v-tabs class="mt-5">
                <v-tab v-for="tab in visibleTabs" :key="tab.tabName" :to="{ name: tab.route }" v-bind:[tab.e2e]="true">
                    <v-icon class="mr-1" small>{{ tab.icon }}</v-icon>
                    {{ tab.tabName }}
                </v-tab>
            </v-tabs>
            <v-divider></v-divider>
            <router-view></router-view>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    data() {
        return {
            tabs: [
                { tabName: 'Profile', icon: 'person', route: 'user-profile', e2e: 'e2e-user-profile' },
                { tabName: 'SSH keys', icon: 'mdi-key', route: 'user-ssh', e2e: 'e2e-user-ssh' },
                { tabName: 'Secrets', icon: 'mdi-lock', route: 'user-secrets', e2e: 'e2e-user-secrets' },
                { tabName: 'Variables', icon: 'mdi-code-equal', route: 'user-env-vars', e2e: 'e2e-user-env-vars' },
                { tabName: 'Table access', icon: 'mdi-database', route: 'user-tables', e2e: 'e2e-user-tables' },
                { tabName: 'Dropbox sync', icon: 'sync', route: 'user-dropbox', e2e: 'e2e-user-dropbox' },
                { tabName: 'API Access', icon: 'mdi-api', route: 'user-api-access', e2e: 'e2e-user-api-access' }
            ]
        }
    },
    computed: {
        visibleTabs() {
            return this.tabs.filter(tab => {
                if (!tab.hidden) return true
                return !this[tab.hidden]()
            })
        }
    }
}
</script>
